<template>
  <div>
    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('general.sectionTitle') }}
        </h2>
        <p class="subtitle">
          {{ $t('general.sectionSubtitle') }}
        </p>
      </header>

      <div class="two-column-section__content">
        <CyAlert
          theme="error"
          :content="errors"/>

        <div
          v-if="loading"
          data-cy="general-info-loader"
          class="d-flex flex-column sk-block pa-4 space-y-7">
          <div class="d-flex space-x-4">
            <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
            <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
          </div>
          <div class="d-flex space-x-4">
            <div class="sk-block sk-img sk-dark sk-w-14 sk-h-14"/>
            <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
          </div>
          <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
          <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
          <div class="sk-block sk-h-6 sk-dark sk-w-24"/>
        </div>

        <v-card
          v-else
          data-cy="general-info-card"
          outlined
          class="pa-4 space-y-2">
          <div class="d-flex space-x-4">
            <v-text-field
              v-model="$v.profile.given_name.$model"
              :label="$t('general.fieldFirstname')"
              :error-messages="nameErrors"
              name="given_name"
              required
              class="required-field"/>

            <v-text-field
              v-model="$v.profile.family_name.$model"
              :label="$t('general.fieldLastname')"
              :error-messages="lastNameErrors"
              name="family_name"
              required
              class="required-field"/>
          </div>

          <div class="d-flex space-x-4">
            <div
              v-if="!profile.picture_url || imageErrors.length"
              class="option-logo__preview option-logo__preview--empty">
              <v-icon>photo_size_select_actual</v-icon>
            </div>
            <img
              v-else
              class="option-logo__preview"
              :src="profile.picture_url"
              alt="avatar">

            <v-text-field
              v-model="profile.picture_url"
              :label="$t('general.fieldAvatar')"
              :error-messages="imageErrors"
              name="picture_url"/>
          </div>

          <div data-cy="language-field">
            <v-select
              v-model="profile.locale"
              :items="localesList"
              :label="$t('forms.fieldLanguage')"
              item-text="name"
              item-value="locale"
              class="required-field"
              required
              hide-selected/>
          </div>

          <v-autocomplete
            v-model="profile.country_code"
            :items="countries"
            :label="$t('forms.fieldCountry')"
            item-text="name"
            item-value="code"
            hide-selected
            clearable/>

          <CyButton
            type="submit"
            data-cy="submit-btn"
            variant="primary"
            icon="done"
            :loading="updating"
            :disabled="!canSave"
            @click.native="updateProfile">
            {{ $t('forms.btnSave') }}
          </CyButton>
        </v-card>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('dangerZone.sectionTitle') }}
        </h2>
      </header>

      <div class="two-column-section__content">
        <div
          v-if="loading"
          data-cy="delete-account-loader"
          class="d-flex align-center sk-block pa-4 space-x-4">
          <div class="flex-grow-1 space-y-2">
            <div class="sk-block sk-dark sk-title sk-w-48"/>
            <div class="sk-block sk-dark sk-title sk-full-width"/>
            <div class="sk-block sk-dark sk-title sk-w-96"/>
          </div>
          <div class="sk-block sk-h-6 sk-dark sk-w-24"/>
        </div>

        <v-card
          v-else
          data-cy="delete-account-card"
          outlined
          class="d-flex align-center pa-4 space-x-4 border-danger">
          <div class="flex-grow-1">
            <h3 class="cy-headline darkgrey--text">
              {{ $t('dangerZone.deleteAccountTitle') }}
            </h3>
            <p class="mt-2 mb-0">
              {{ $t('dangerZone.deleteAccountDescription') }}
            </p>
          </div>
          <CyButton
            variant="secondary"
            theme="error"
            icon="delete"
            @click.native="$toggle.showDeleteAccountModal">
            {{ $t('dangerZone.deleteAccountButton') }}
          </CyButton>
        </v-card>
      </div>
    </section>

    <CyModal
      v-if="showDeleteAccountModal"
      :header-title="$t('deleteUserAccountModal.header')"
      :loading="deleting"
      :action-btn-text="$t('deleteUserAccountModal.button')"
      :action-btn-func="deleteUserAccount"
      :action-btn-disabled="deleting"
      :cancel-btn-func="() => $toggle.showDeleteAccountModal(false)"
      :cancel-btn-disabled="deleting"
      small
      modal-type="delete">
      <template slot="default">
        <p>{{ $t('deleteUserAccountModal.content') }}</p>
        <p>{{ $t('deleteUserAccountModal.contentWarning1') }}</p>
        <p>{{ $t('deleteUserAccountModal.contentWarning2') }}</p>
        <p class="mt-12 mb-0">
          {{ $t('forms.areYouSure') }}
        </p>
      </template>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb, imageExists } from '@/utils/helpers'
import { required, url, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageUserAccount',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.userAccount'), [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  validations: {
    profile: {
      picture_url: {
        url,
        imageFound: (url) => _.isEmpty(url) ? true : imageExists(url, { external: true }),
      },
      given_name: {
        required,
        minLength: minLength(2),
        alphaWithSpecial: (val) => !val ? true : REGEX.PROFILE_NAME.test(val),
      },
      family_name: {
        required,
        minLength: minLength(2),
        alphaWithSpecial: (val) => !val ? true : REGEX.PROFILE_NAME.test(val),
      },
    },
  },
  data: () => ({
    profile: null,
    loading: true,
    updating: false,
    deleting: false,
    showDeleteAccountModal: false,
  }),
  computed: {
    ...mapState({
      countries: (state) => state.countries,
    }),
    ...mapState('user', {
      userProfile: (state) => state.profile,
      errors: (state) => state.errors.profile,
    }),
    ...mapGetters('i18n', [
      'localesList',
    ]),
    hasChanges () {
      return !_.isEqual(this.profile, this.userProfile)
    },
    canSave () {
      return this.hasChanges &&
        !this.$v.profile.$invalid &&
        !this.updating &&
        !this.deleting
    },
    nameErrors () {
      const errors = []
      const { required, minLength, alphaWithSpecial } = this.$v.profile.given_name
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('general.fieldFirstnameLength'))
      if (!alphaWithSpecial) errors.push(this.$t('general.fieldInvalidFormat'))
      return errors
    },
    lastNameErrors () {
      const errors = []
      const { required, minLength, alphaWithSpecial } = this.$v.profile.family_name
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('general.fieldLastnameLength'))
      if (!alphaWithSpecial) errors.push(this.$t('general.fieldInvalidFormat'))
      return errors
    },
    imageErrors () {
      const errors = []
      const { $invalid, url, imageFound } = this.$v.profile.picture_url
      if (!$invalid) return errors
      if (!url) errors.push(this.$t('forms.fieldInvalidUrl'))
      if (!imageFound) errors.push(this.$t('forms.fieldImageNotFound'))
      return errors
    },
  },
  async mounted () {
    await this.FETCH_COUNTRIES()
    this.profile = _.cloneDeep(this.userProfile)
    this.loading = false
  },
  beforeDestroy () {
    this.CLEAR_USER_ERRORS()
  },
  methods: {
    ...mapActions([
      'FETCH_COUNTRIES',
    ]),
    ...mapActions('user', [
      'UPDATE_PROFILE',
      'DELETE_ACCOUNT',
    ]),
    ...mapMutations('user', [
      'CLEAR_USER_ERRORS',
    ]),
    ...mapMutations('i18n', [
      'CHANGE_LOCALE',
    ]),
    async deleteUserAccount () {
      const { $router } = this
      this.deleting = true
      await this.DELETE_ACCOUNT({ $router })
      this.showDeleteAccountModal = false
      this.deleting = false
    },
    async updateProfile () {
      const { profile } = this
      this.updating = true
      this.CHANGE_LOCALE(profile.locale)
      await this.UPDATE_PROFILE({ profile })
      this.updating = false
      if (_.isEmpty(this.errors)) this.profile = _.cloneDeep(this.userProfile)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userAccount',
        dangerZone: {
          sectionTitle: 'Danger zone',
          deleteAccountTitle: 'Delete account',
          deleteAccountDescription: 'Deleting your account is a permanent action that cannot be undone.',
          deleteAccountButton: 'Delete your account…',
        },
        deleteUserAccountModal: {
          header: 'Delete your account?',
          content: `You're about to delete your account and leave the platform.`,
          contentWarning1: `This operation might fail if you're the sole admin of an organization that has other members. In this scenario, you will need to assign the admin @:role to someone else, remove all other members from the organization or delete the organization manually.`,
          contentWarning2: `Also, make sure any resources linked to running entities (projects, pipelines, stacks, ...etc) are manually deleted before continuing. Any other valuable information, access and membership (i.e. @:teams and organizations) you're linked to will be automatically removed. This operation can not be undone.`,
          button: 'Delete account',
        },
        general: {
          sectionTitle: '@:General',
          sectionSubtitle: 'Update your general information and personal preferences.',
          fieldAvatar: 'Profile image URL',
          fieldFirstname: 'Firstname',
          fieldFirstnameLength: 'The firstname must have at least 2 characters',
          fieldInvalid: 'Invalid value',
          fieldInvalidFormat: 'Invalid format',
          fieldLastname: 'Lastname',
          fieldLastnameLength: 'The lastname must have at least 2 characters',
        },
      },
      es: {
        title: '@:routes.userAccount',
        dangerZone: {
          sectionTitle: 'Zona peligrosa',
          deleteAccountTitle: 'Eliminar cuenta',
          deleteAccountDescription: 'Eliminar su cuenta es una acción permanente que no se puede deshacer.',
          deleteAccountButton: 'Eliminar su cuenta…',
        },
        deleteUserAccountModal: {
          header: '¿Eliminar su cuenta?',
          content: 'Estas a punto de eliminar su cuenta y abandonar la plataforma.',
          contentWarning1: 'Esta operación puede fallar si usted es el único administrador de una organización que tiene otros miembros. En este escenario, deberá asignar el @:role de administrador a otra persona, eliminar a todos los demás miembros de la organización o eliminar la organización manualmente.',
          contentWarning2: 'Además, asegúrese de que todos los recursos vinculados a entidades en ejecución (proyectos, pipelines, stacks, ... etc.) se eliminen manualmente antes de continuar. Cualquier otra información valiosa, acceso y membresía (es decir, @:teams y organizaciones) a la que esté vinculado se eliminará automáticamente. Esta operación no se puede deshacer.',
          button: 'Eliminar su cuenta',
        },
        general: {
          sectionTitle: '@:General',
          sectionSubtitle: 'Actualiza tu información general y preferencias personales.',
          fieldAvatar: 'URL de la imagen de perfil',
          fieldFirstname: 'Nombre',
          fieldFirstnameLength: 'El nombre debe tener al menos 2 carácteres',
          fieldInvalid: 'Contenido no valido',
          fieldInvalidFormat: 'Formato inválido',
          fieldLastname: 'Apellido',
          fieldLastnameLength: 'El apellido debe tener al menos 2 carácteres',
        },
      },
      fr: {
        title: '@:routes.userAccount',
        dangerZone: {
          sectionTitle: 'Zone de danger',
          deleteAccountTitle: 'Supprimer le compte',
          deleteAccountDescription: 'La suppression de votre compte est une action permanente et irréversible.',
          deleteAccountButton: 'Supprimer votre compte…',
        },
        deleteUserAccountModal: {
          header: 'Supprimer votre compte ?',
          content: 'Vous êtes sur le point de supprimer votre compte et donc de quitter la plateforme.',
          contentWarning1: `Cette opération peut échouer si vous êtes le seul administrateur d'une organisation qui compte d'autres membres.Dans ce scénario, vous devrez attribuer le @:role d'administrateur à quel qu'un d 'autre, supprimer tous les autres membres de l'organisation ou supprimer l'organisation manuellement.`,
          contentWarning2: `Assurez-vous également que toutes les ressources liées aux entités en cours d'exécution (projets, pipelines, piles, etc.) sont supprimées manuellement avant de continuer. Toute autre information précieuse, accès et appartenance (c.-à-d. @:teams et organisations) auxquels vous êtes lié sera automatiquement supprimée. Cette opération ne peut pas être annulée.`,
          button: 'Supprimer votre compte',
        },
        general: {
          sectionTitle: '@:General',
          sectionSubtitle: 'Modifiez vos informations générales et vos préférences personnelles.',
          fieldAvatar: `URL de l'image de profil`,
          fieldFirstname: 'Prénom',
          fieldFirstnameLength: 'Le prénom doit contenir plus de 2 caractères',
          fieldInvalid: 'Valeur non valide',
          fieldInvalidFormat: 'Format invalide',
          fieldLastname: 'Nom',
          fieldLastnameLength: 'Le nom doit contenir plus de 2 caractères',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.option-logo,
.option-favicon {
  display: flex;

  &__preview {
    object-fit: contain;
    align-self: center;
    min-width: 56px;
    max-width: 56px;
    height: 56px;
    border: #{$spacer-2} solid cy-get-color("grey", "light-3");
    border-radius: 4px;
    background-color: cy-get-color("grey", "light-3");

    .option-favicon & {
      padding: 14px;
    }

    &--empty {
      display: flex;
      justify-content: center;
      border: 1px dashed cy-get-color("primary", "light-4");
      background: none;

      ::v-deep {
        .v-icon {
          color: cy-get-color("primary", "light-4");
        }
      }
    }
  }
}

.border-danger {
  border-color: cy-get-color("error");
}
</style>
